@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-6 text-nikayla-gold;
}

input:focus-within ~ label {
  @apply text-nikayla-gold text-base;
} */

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 600px;
      margin: 1.75rem auto;
  }
}